<template>
  <b-modal id="setoran-modal" title="Form Setor Pembayaran">
    <template #modal-header>
      <h4>Form Setor Pembayaran</h4>
    </template>
    <b-form-group label="Jumlah Pembayaran">
      <h2 class="my-2 text-center text-danger">
        <strong>Rp {{ formatRupiah(totalNominal) }}</strong>
      </h2>
    </b-form-group>
    <b-form-group label="Tanggal Setoran">
      <b-form-input type="date" v-model="form.tanggal"></b-form-input>
    </b-form-group>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" @click.prevent="submit" class="mr-1"
          >Setorkan</b-button
        >
        <b-button variant="secondary" @click.prevent="$bvModal.hide('setoran-modal')">Batal</b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormSelect,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    flatPickr,
  },
  computed: {
    items() {
      return this.$store.state.setoran.checkedDatas
    },
    totalNominal() {
      if(!this.items) {
        return 0
      }

      return this.items.reduce((total, item) => total += (item.metode_ket === 'Giro' ? item.giro : item.nominal), 0)
    }
  },
  data() {return {
    localItems: [],
    form: {
      tanggal: this.getCurrentDate(),
      keterangan: null,
      id_sales: null,
      id_gudang_m: null,
      id_gudang: null,
    },
    id_gudang: [],
  };},
  async mounted() {
    this.getDataGudang1();
  },
  methods: {
    submit() {
      if (!this.form.tanggal) {
        this.displayError({
          message: "Harap pilih tanggal request!",
        });
        return false;
      }

      const payload = {
        id_sales: this.user.karyawan ? this.user.karyawan.id : null,
        id_gudang_m: null,
        pembayaran: []
      }

      if(this.myGudang) payload.id_gudang_m = this.myGudang.id;
      
      // set payload rincian
      this.items.map((pembayaran) => {
        payload.pembayaran.push({
          id_pembayaran: pembayaran.id,
        })
      })
      
      this.$emit("submit", payload);
    },
    async getDataGudang1() {
      await this.$store
        .dispatch("gudang/getData", { jenis: 1 })
        .then(() => {
          let gudang = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          gudang.map((item) => {
            item.value = item.id;
            item.text = item.nama_gudang;
          });

          this.id_gudang = gudang;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
  },
};
</script>
