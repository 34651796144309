var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "setoran-modal",
      "title": "Form Setor Pembayaran"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('h4', [_vm._v("Form Setor Pembayaran")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Setorkan")]), _c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$bvModal.hide('setoran-modal');
            }
          }
        }, [_vm._v("Batal")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Pembayaran"
    }
  }, [_c('h2', {
    staticClass: "my-2 text-center text-danger"
  }, [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.totalNominal)))])])]), _c('b-form-group', {
    attrs: {
      "label": "Tanggal Setoran"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal", $$v);
      },
      expression: "form.tanggal"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }