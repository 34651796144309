<template>
    <b-row>
        <b-col lg="3" sm="6">
            <statistic-card-horizontal icon="LayersIcon" color="primary" :statistic="`Rp ${formatRupiah(summarize.tunai)}`" statistic-title="Tunai" />
        </b-col>
        <b-col lg="3" sm="6">
            <statistic-card-horizontal icon="CreditCardIcon" color="success" :statistic="`Rp ${formatRupiah(summarize.transfer)}`" statistic-title="Transfer" />
        </b-col>
        <b-col lg="3" sm="6">
            <statistic-card-horizontal icon="FileIcon" color="warning" :statistic="`Rp ${formatRupiah(summarize.giro)}`" statistic-title="Giro" />
        </b-col>
        <b-col lg="3" sm="6">
            <statistic-card-horizontal icon="FileTextIcon" color="danger" :statistic="`Rp ${formatRupiah(summarize.nota_putih)}`" statistic-title="Nota Putih" />
        </b-col>
    </b-row>
</template>
<script>
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue"
import {BRow, BCol} from 'bootstrap-vue'
export default {
    props: {
        summarize: {
            type: Object,
            default: () => ({
                tunai: 0,
                transfer: 0,
                giro: 0,
                nota_putih: 0
            })
        }
    },
    components: {
        StatisticCardHorizontal, BRow, BCol
    }
}
</script>