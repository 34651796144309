<template>

  <b-row>
    <b-col cols="12">
      <b-tabs content-class="pt-1 w-full" fill>
        <b-tab title="Belum Setor" :active="stokTab">
          <!-- Pembayaran yang belum disetor ke Finance -->
          <ungiven-payment ></ungiven-payment>
          <!-- / -->
        </b-tab>
        <b-tab title="Data Setoran Diterima" :active="pengembalianTab">

          <data-setoran :active="pengembalianTab" />
        </b-tab>
        <b-tab title="Sudah Setor" :active="sudahsetorTab">
          <!-- Pembayaran yang sudah disetor ke Finance dan siap dimasukan ke Akun dan Kas -->
          <given-payment :active="sudahsetorTab" />
          <!-- / -->
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>
<script>
import UngivenPayment from "./components/UngivenPayment.vue";
import GivenPayment from "./components/GivenPayment.vue";
import DataSetoran from "./components/DataSetoran.vue";
import { BTabs, BTab, BRow, BCol, BCard } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";

export default {
  components: {
    StatisticCardHorizontal,
    UngivenPayment,
    GivenPayment,
    DataSetoran,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
  },
  data: () => ({
    isVerticalDevice: null,
    stokTab: false,
    sudahsetorTab: false,
    pengembalianTab: false,
  })
};
</script>
