var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "LayersIcon",
      "color": "primary",
      "statistic": ("Rp " + (_vm.formatRupiah(_vm.summarize.tunai))),
      "statistic-title": "Tunai"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "CreditCardIcon",
      "color": "success",
      "statistic": ("Rp " + (_vm.formatRupiah(_vm.summarize.transfer))),
      "statistic-title": "Transfer"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "FileIcon",
      "color": "warning",
      "statistic": ("Rp " + (_vm.formatRupiah(_vm.summarize.giro))),
      "statistic-title": "Giro"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "FileTextIcon",
      "color": "danger",
      "statistic": ("Rp " + (_vm.formatRupiah(_vm.summarize.nota_putih))),
      "statistic-title": "Nota Putih"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }