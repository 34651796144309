<template>
  <b-overlay :show="loading">
    <b-card>
      <b-row>
        <b-col sm="12" md="6" class="mb-2">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" class="mb-2">
          <b-form-group
            :label="$t('Filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- table -->
        <b-col cols="12">
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="setor"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(order)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(tanggal)="{ item }">
            {{humanDate(item.tanggal)}}
          </template>
            <template #cell(pembayaran.metode)="{ item }">
              {{ getMetode(item) }}
            </template>
            <template #cell(pembayaran.nominal)="{ item }">
              <strong>
                Rp {{ formatRupiah( getNominal(item) ) }}
              </strong>
            </template>
            <template #cell(aksi)="{ item }">
              <div>
                <b-button
                  size="sm"
                  @click.prevent="printAttachment(item)"
                  variant="outline-warning"
                >
                  <feather-icon icon="PrinterIcon"></feather-icon>
                </b-button>
                <b-button
                  size="sm"
                  @click.prevent="remove(item)"
                  class="mx-1"
                  variant="outline-danger"
                  v-if="hasPermissionDelete"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <!-- / -->
        <!-- pagination -->
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
        <!-- / -->
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import SetoranSummarize from './Summarize.vue'
import {
  BOverlay,
  BFormCheckbox,
  BPagination,
  BButton,
  BCard,
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    SetoranSummarize,
    BFormCheckbox,
    BButton,
    BPagination,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormSelect,
  },
  props: ["active"],
  computed: {
    getSetoranSummarize() {
      return this.$store.getters['penjualan/getSetoranSummarize']
    },
    gudangKendaraanId() {
      return this.$route.params.id;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  data: () => ({
    allChecked: false,
    checkedPengembalian: [],
    totalRows: 1,
    currentPage: 1,
    sortBy: "id",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    perPage: 10,
    pageOptions: [10, 30, 50],
    loading: false,
    setor: [],
    hasPermissionDelete: false,
    fields: [
      { key: "order", label: "No" },
      { key: "no", label: "No. Setoran" },
      { key: "tanggal", label: "Tanggal", sortable: true },
      { key: "sales.nama_lengkap", label: "Sales" },
      { key: "pembayaran.metode", label: "metode" },
      { key: "pembayaran.nominal", label: "nominal" },
      { key: "aksi", label: "#" },
    ],
    swalOptions: {
      title: `Anda Yakin?`,
      text: `Hasil setoran akan dihapus dari akun yang tersimpan?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
      customClass: {
        confirmButton: "btn btn-outline-success",
        cancelButton: "btn btn-danger ml-1",
      },
      buttonsStyling: false,
    },
  }),
  watch: {
    active(val) {
      if (val) {
        this.getSetoran();
      }
    },
  },
  methods: {
    async getDeletePermission() {
      const menu = await this.currentMenu()
      const params = {
          level_id: this.user.level.id
      }
      if(menu) {
          params.menu_id = menu.id
      }

      const status = await this.$store.dispatch('statusrole/getData', params)

      // data_status => 1 permission untuk hapus setoran yang sudah diterima (masuyk akun)
      this.hasPermissionDelete = status.some(status => status.data_status == 1)
    },
    async printAttachment(item) {
      this.loading = true
      const response = await this.$store.dispatch("penjualan/printAjuan", item.id);
      this.loading = false
      window.open(URL.createObjectURL(response))
    },
    getMetode(item){
      return item.pembayaran.map(bayar => bayar.metode).join(', ')
    },
    getNominal(item){
      return item.pembayaran.reduce((total, item) => total += item.nominal, 0)
    },
    remove(item) {
      if(!this.hasPermissionDelete) {
        this.displayError({
          message: 'Anda tidak memiliki izin untuk menghapus data setoran'
        })
        return false
      }
      this.$swal(this.swalOptions).then(async (res) => {
        if (res.value) {
          try {
            this.loading = true;
            await this.$store.dispatch("penjualan/deleteSetoran", { id_setor: item.id, fungsi: 2 });
            await this.getSetoran();
            this.loading = false;
          } catch (e) {
            this.loading = false;
            this.displayError(e);
            return false;
          }
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getSetoran() {
      const params = {
        order: 'desc'
      };
      if (this.isSales) {
        params.id_sales = this.user.karyawan.id;
        params.order = "desc"
      }if (this.isFinance) {
        params.order = "desc"
      }
      this.loading = true;
      this.setor = await this.$store.dispatch("penjualan/getDataSetoran", params);
      this.totalRows = this.setor.length
      const summarize = {
        tunai: [],
        transfer: [],
        giro: [],
        nota_putih: []
      }

      this.setor.map(setor => {
        if(setor.pembayaran && setor.pembayaran.length > 0) {
          setor.pembayaran.map(payment => {
            if(payment.metode.toLowerCase() == 'tunai') {
              summarize.tunai.push(payment)
            }
            else if(payment.metode.toLowerCase() == 'transfer') {
              summarize.transfer.push(payment)
            }
            else if(payment.metode.toLowerCase() == 'giro') {
              summarize.giro.push(payment)
            }
            else if(payment.metode.toLowerCase() == 'nota putih') {
              summarize.nota_putih.push(payment)
            }
          })
        }
      })

      this.$store.commit('penjualan/SET_SUMMARIZE_SETORAN', summarize)
      this.loading = false;
    },
  },
  async created() {
    await this.getSetoran();
    this.getDeletePermission()
  },
};
</script>
