<template>
    <b-modal title="Jumlah Setoran" id="preview-modal" no-close-on-backdrop no-close-on-esc>
        <h2 class="text-center text-danger my-2">
            <strong>Rp {{ formatRupiah(totalSetoran) }}</strong>
        </h2>
        <b-form-group label="Diterima Setoran sebagai:" class="mb-2">
            <v-select v-model="jenisSetoran" label="text" :reduce="option => option.value" :options="[{value: 1, text: 'Dokumen / Data'}, {value: 2, text: 'Uang'}]"></v-select>
        </b-form-group>
        <b-form-group v-if="jenisSetoran == 2" label="Pilih Akun" class="mb-2">
            <v-select label="text" :options="akunOptions" v-model="akun"></v-select>
        </b-form-group>
        <b-form-group v-if="jenisSetoran == 2" label="Pilih Kas" class="mb-2">
            <v-select label="text" :options="kasOptions" :reduce="option => option.value" v-model="form.id_kas"></v-select>
        </b-form-group>
        <br>
        <template #modal-footer>
            <b-button variant="primary" :disabled="!isValidForm" @click.prevent="submit">
                Simpan
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BFormGroup, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    components: {
        BModal, BFormGroup, BButton, vSelect
    },
    data: () => ({
        akun: null,
        form: {
            id_akun: null,
            id_kas: null
        },
        jenisSetoran: null
    }),
    computed: {
        isValidForm() {
            if(!this.jenisSetoran) {
                return false
            }

            if(this.jenisSetoran == 2 && (!this.akun || !this.form.id_kas)) {
                return false
            }

            return true
        },
        isKas() {
            if(this.akun && this.akun.kategori && this.akun.kategori.kategori == 'Kas & Bank') {
                return true
            }

            return false
        },
        checkedSetoran() {
            return this.$store.state.sudahsetor.checkedSetoran
        },
        totalSetoran() {
            if(!this.checkedSetoran) {
                return 0
            }

            return this.checkedSetoran.reduce((total, item) => total += (item.metode_ket === 'Giro' ? item.giro : item.nominal), 0)
        },
        kasOptions() {
            return JSON.parse( JSON.stringify(this.$store.state.sudahsetor.kasOptions) )
        },
        akunOptions() {
            return JSON.parse( JSON.stringify(this.$store.state.sudahsetor.akunOptions) )
        }
    },
    methods: {
        submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap pastikan form dipilih!'
                })
                return false
            }

            const payloads = []
            this.checkedSetoran.map(pembayaran => {
                const payload = {
                    id_pembayaran: pembayaran.id,
                    status: this.jenisSetoran
                }

                if(this.jenisSetoran == 2 ) {
                    payload.id_akun = this.akun.value
                    payload.id_kas = this.form.id_kas ? this.form.id_kas : null
                }
                payloads.push(payload)
            })

            this.$emit('submit', payloads)
        }
    }
}
</script>