<template>
    <section>
        <b-card>
            <a href="#" @click.prevent="$emit('view', {component: 'ListPengembalian', pengembalian: null})">Kembali</a>
            <h3 class="mt-2 mb-2">Detail Pengembalian</h3>
            <b-row>
                <b-col sm="6" md="4">
                    <b-form-group label="Sales">
                        <strong>{{ item.sales ? item.sales.nama_lengkap : item.id_sales }}</strong>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                    <b-form-group label="Tanggal Request Pengembalian">
                        <strong>{{ humanDate(item.tanggal) }}</strong>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                    <b-form-group label="Keterangan">
                        <strong>{{ item.keterangan }}</strong>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
    </section>
</template>
<script>
import {BCard, BRow, BCol, BFormGroup} from 'bootstrap-vue'
export default {
    props: ['pengembalian'],
    data: () => ({
        item: {

        }
    }),
    components: {
        BCard, BRow, BCol, BFormGroup
    },
    watch: {
        pengembalian(item) {
            if(item) {
                Object.assign(this.item, item)
            }
        }
    },
    created() {
        if(this.pengembalian) {
            this.item = this.pengembalian
        }
    }
}
</script>